import { css } from "@emotion/css";
import styled from "@emotion/styled";
import type { FC } from "react";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import InDialog from "src/ui/components/InDialog/InDialog";
import useGoToOrBack from "src/ui/hooks/useGoToOrBack";
import { CalendlyEventDetails } from "../../CallbackRequest/CallbackRequest";
import { tracker, userPreferences, userState } from "src/state/state";
import Loader from "../../Loader/Loader";
import Translate from "../../Translate/Translate";
import { TaskResponse } from "@9amhealth/openapi/generated/models/TaskResponse";
import { Button } from "react-aria-components";
import { IconChevronRight } from "src/constants/icons";

const Centered = styled.div`
  display: grid;
  place-items: center;
  width: 100%;
  height: 100%;
  text-align: center;
  --bottom-btn-height: 3.125rem;
  padding-bottom: var(--bottom-btn-height);
  margin-top: -20px;
`;

const BarInfo = styled.div`
  background: var(--Greys-Light-Gray, #e6e3db);
  border-radius: 0;
  border: none;
  width: 100%;
  padding: 1rem;
  outline: none;
  position: sticky;
  z-index: 100;
  top: 0;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const BarButton = styled(Button)`
  background: var(--Primary-criticalRed, #ff7663);
  border-radius: 0;
  padding: 1rem;
  border: none;
  width: 100%;
  outline: none;
  position: sticky;
  z-index: 100;
  top: 0;

  > div::before {
    opacity: 0;
  }

  svg {
    display: inline-block;
    height: 1rem;
    width: auto;
    transform: scale(1.5) translate(0.1rem, 0.1rem);

    path {
      fill: currentColor;
    }
  }
`;

const ionModalStyles = css`
  // popup options for iframe
  ion-content {
    &::part(scroll) {
      background: white;
    }
  }
`;

const DetailFrame = styled.iframe`
  outline: none;
  border: none;
  width: 100%;
  overflow: hidden;
  transition: opacity 0.5s ease-in-out;
  transition-property: opacity;
  transition-delay: 0.2s;
  margin: 0;
  padding-bottom: 0 !important;
  position: relative;
  z-index: 80;
  opacity: var(--opacity, 0);
  scroll-margin-top: 8rem;
`;

const AppointmentBookingDialog: FC<{
  returnUrl: string;
}> = ({ returnUrl }) => {
  const navigate = useGoToOrBack();
  const [modalRef, setModalRef] =
    React.useState<React.RefObject<HTMLIonModalElement> | null>(null);

  const [query] = useSearchParams();
  const urlQueryParam = query.get("url") ?? "";
  const title = query.get("title") ?? "";
  const taskStatus: TaskResponse.status | "" = (query.get("status") ??
    "") as TaskResponse.status;
  const [status, setStatus] = useState<string>("prompt");

  const onClose = () => {
    void modalRef?.current?.dismiss();
    navigate(returnUrl, {
      multiBack: true,
      replace: true
    });
  };

  const parsedUrl = useMemo(() => {
    try {
      const email = userState.state.userData?.email ?? "";
      const name = userPreferences.displayName ?? "";
      const currentDomain = window.location.hostname;
      const url = new URL(urlQueryParam);
      url.searchParams.set("embed_domain", currentDomain);
      url.searchParams.set("embed_type", "Inline");
      url.searchParams.append("email", email);
      url.searchParams.append("name", name);
      url.searchParams.append("utm_term", title);

      return url.toString();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (e) {
      return null;
    }
  }, [urlQueryParam]);

  const calendlyListener = useCallback(
    (event: MessageEvent<CalendlyEventDetails>) => {
      if (event.origin !== "https://calendly.com") {
        return;
      }
      const { data } = event;
      const iframe = document.getElementById("app-query-iframe");
      switch (data.event) {
        case "calendly.event_scheduled":
          tracker.track(`${title} [Calendly] Schedule Completed`, {
            data: {
              inviteeApi: data.payload.invitee.uri,
              eventApi: data.payload.event.uri
            }
          });
          setStatus("schedule-completed");
          iframe?.scrollIntoView();
          document.dispatchEvent(
            new CustomEvent("scheduled-appointment", {
              detail: {
                type: "calendly",
                payload: data.payload
              }
            })
          );
          break;
        case "calendly.event_type_viewed":
          tracker.track(`${title} [Calendly] Event Type Viewed`);
          iframe?.scrollIntoView();
          break;
        case "calendly.date_and_time_selected":
          tracker.track(`${title} [Calendly] Date and Time Selected`);
          iframe?.scrollIntoView();
          break;
        case "calendly.page_height":
          if (iframe) {
            iframe.style.setProperty("--opacity", "1");
            iframe.style.height = data.payload.height;
          }
          break;
      }
    },
    []
  );

  const skipTask = () => {
    tracker.track(`${title} [Calendly] Skip Task`);
    document.dispatchEvent(new CustomEvent("skipped-appointment"));
    onClose();
  };

  useEffect(() => {
    window.addEventListener("message", calendlyListener);
    return () => {
      window.removeEventListener("message", calendlyListener);
    };
  }, []);

  return (
    <InDialog
      title={title}
      onClose={onClose}
      setRef={setModalRef}
      returnUrl={returnUrl}
      className={ionModalStyles}
      simple
      width="min(calc(100% - 4rem), 120rem)"
      height="min(calc(100% - 4rem), 70rem)"
    >
      <div>
        <Loader fixed active />
        {taskStatus === "AVAILABLE" && (
          <BarButton onPress={skipTask}>
            <Translate msg="task.skip" />
            <IconChevronRight />
          </BarButton>
        )}
        {taskStatus === "SKIPPED" && (
          <BarInfo>
            <Translate msg="task.hasBeenSkip" />
          </BarInfo>
        )}
        {parsedUrl && <DetailFrame src={parsedUrl} id="app-query-iframe" />}
        {status === "schedule-completed" && (
          <Centered style={{ marginTop: "2rem" }}>
            <Button onPress={onClose}>
              <Translate msg="continue" />
            </Button>
          </Centered>
        )}
      </div>
    </InDialog>
  );
};

export default AppointmentBookingDialog;
